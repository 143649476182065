export const TRAINEES_COLUMNS = [
    { name: 'Cognome', prop: 'last_name' },
    { name: 'Nome', prop: 'first_name' },
    { name: 'email', prop: 'email' },
    { name: 'Telefono', prop: 'phone' },
    { name: 'TipoCorso', prop: 'class_type_name' },
    { name: 'AnnoCorso', prop: 'class_year' }
];

export const TRAINERS_COLUMNS = [
    { name: 'Cognome', prop: 'last_name' },
    { name: 'Nome', prop: 'first_name' },
    { name: 'email', prop: 'email' },
    { name: 'Telefono', prop: 'phone' },
    { name: 'Telegram', prop: 'telegram_chat_id' }
];

export const CLASS_SESSION_COLUMNS = [
    { name: 'Argomento', prop: 'topic' },
    { name: 'Data', prop: 'date' },
    { name: 'Allievi', prop: 'allieviCount' }
];

export const BOAT_SESSION_COLUMNS = [
    { name: 'Argomento', prop: 'topic' },
    { name: 'Data', prop: 'date' },
    { name: 'Istruttore', prop: 'teacher_first_last_name' },
    { name: 'Prenotati', prop: 'count_reserved' },
    { name: 'Presenti', prop: 'count_present' },
    { name: 'Link', prop: 'link' }
];
